import React from "react"

export default function DiagonalArrowIcon({
  className,
  width,
  height,
  color,
  onClick,
}) {
  return (
    <svg
      width={width ? width : "31"}
      height={height ? height : "32"}
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M30.5 15.75L0.999999 15.75M0.999999 15.75L7.5 9.25M0.999999 15.75L7.5 22.25"
        stroke={color ? color : "#FF0000"}
      />
    </svg>
  )
}
