import React from "react"
import styles from "./articles.module.css"
import ArticlePreview from "../components/articlePreview"
import Layout from "../components/layout"
import NewsletterSubscribe from "../components/newsletterSubscribe"
import SEO from "../components/seo"

export default function Articles({ pageContext: { articles } }) {
  return (
    <Layout
      mainClassName={styles.wrapper}
      pageWrapperClassName={styles.fullPageWrapper}
    >
      <SEO title="graphica.ai stories" />
      <h1>
        latest <span>stories</span>
      </h1>
      <p>
        We love to tell stories about how we build systems and bright ideas
        behind them
      </p>
      {articles && articles[0] && (
        <div className={styles.firstStoriesBlock}>
          <ArticlePreview
            articleLink={`/stories/${articles[0].id}`}
            header={articles[0].title}
            description={articles[0].shortDescription}
            date={articles[0].publishDate}
            category={articles[0].article_status.title}
            imgLink={articles[0].coverImage}
            className={styles.articlePreview}
            articleInformationClassName={styles.articlePreviewInformation}
          />
          <NewsletterSubscribe className={styles.newsletterSubscribe} />
        </div>
      )}
      {articles && (articles[1] || articles[2]) && (
        <div className={styles.secondStoriesBlock}>
          <ArticlePreview
            articleLink={`/stories/${articles[1].id}`}
            header={articles[1].title}
            description={articles[1].shortDescription}
            date={articles[1].publishDate}
            category={articles[1].article_status.title}
            imgLink={articles[1].coverImage}
            className={styles.articlePreview}
            articleInformationClassName={styles.articlePreviewInformation}
          />
          {articles[2] && (
            <ArticlePreview
              articleLink={`/stories/${articles[2].id}`}
              header={articles[2].title}
              description={articles[2].shortDescription}
              date={articles[2].publishDate}
              category={articles[2].article_status.title}
              imgLink={articles[2].coverImage}
              className={styles.articlePreview}
              articleInformationClassName={styles.articlePreviewInformation}
            />
          )}
        </div>
      )}
      <div className={styles.thirdStoriesBlock}>
        {articles &&
          articles
            .slice(3)
            .map(article => (
              <ArticlePreview
                key={article.id}
                articleLink={`/stories/${article.id}`}
                header={article.title}
                description={article.shortDescription}
                date={article.publishDate}
                category={article.article_status.title}
                imgLink={article.coverImage}
                className={styles.articlePreview}
                articleInformationClassName={styles.articlePreviewInformation}
              />
            ))}
      </div>
    </Layout>
  )
}
